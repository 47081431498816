import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import config from '../../config/appConfig';

export const apiSlice = createApi({
	reducerPath: 'api',
	baseQuery: fetchBaseQuery({
		baseUrl: config && config.copilot && config.copilot.baseUrl ? `${config.copilot.baseUrl}/api/v1` : 'https://copilot-develop.sandbox.icimsmco.net/api/v1/'
	}),
	endpoints: () => ({}),
	tagTypes: ['library']
});
