import Axios from 'axios';
// These imports are absolute, and this works because of the jsconfig.json file in the root of the project
import config from 'config';

// get base api url from config
const apiBaseUrl = config.api.baseUrl;

// preconfigured axios instance with base url and version built in
export const axios = Axios.create({
	baseURL: apiBaseUrl
});

// simplified interface, encourages consistency
export const api = {
	todos: {
		list: (options) => axios.get('/todos', options)
	},
	jobs:{
		list: (options) => axios.get('/api/jobs', options)
	},
	getUserData: (options) => axios.get('/pendo', options),
	getCancelSource: () => Axios.CancelToken.source(),
	isCancelError: Axios.isCancel,
	resolveMocking: async () => {
		// this must be done before the app can be rendered
		if (config.api.mocks) {
			// eslint-disable-next-line no-inline-comments
			const initMocks = await import(
				/* webpackChunkName: "todoMock" */
				'../config/apiMocks'
			);
			initMocks.default(axios);
		}
	}
};

export default api;
