/* eslint-disable no-magic-numbers */
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import VolumeUpOutlinedIcon from '@mui/icons-material/VolumeUpOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useSpeechSynthesis } from 'react-speech-kit';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { Button, Paper } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import Snackbar from '@mui/material/Snackbar';
import { useSaveMutation } from 'redux/slices/interviewAssistantSlice';

function Answer({ questionNumber, questionData, t ,lang}) {
	const { question, answer, reasonForRecommendation, skills } = questionData;
	const [isCopied, setIsCopied] = React.useState(false);
	const [readMore, setReadMore] = React.useState(false);
	const [highlight, setHighlight] = React.useState(false);
	const [isRecommendedOpen, setIsRecommenedOpen] = React.useState(false);
	const { speak, speaking, cancel } = useSpeechSynthesis();
	const [save] = useSaveMutation();
	// const getLibrarySize = useGetLibrarySizeQuery();

	const showReadMoreButton = answer.length > 300;
	const answerOnReadLess = showReadMoreButton ? answer.substring(0, 300).concat('......') : answer;

	const handleOnClickVolumeIcon = () => {
		if (!speaking) {
			speak({ text: question });
		} else cancel();
	};

	const onCopy = () => {
		const copiedText = question.concat('\n').concat(answer);
		navigator.clipboard.writeText(copiedText);
		setIsCopied(true);
	};

	const handleShowRecommendedText = () => {
		setIsRecommenedOpen(!isRecommendedOpen);
	};
	const onVote = () => {
		setHighlight(true);
		const payload = {
			question,
			answer,
			skills,
			reasonForRecommendation,
			lang
		};
		if (!highlight) save(payload);
	};

	return (
		<>
			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={isCopied}
				onClose={() => setIsCopied(false)}
				message={t('answer_is_copied_to_clipboard')}
				key={questionNumber}
			/>
			<Paper sx={{ p: 2.125, pt: 3 }}>
				<Stack direction="row" justifyContent="space-between">
					<Stack direction="row" alignItems="center">
						<Typography variant="h3" sx={{ mb: 2.875 }}>
							{`${questionNumber}. ${question}`}
							<Tooltip title={t('text_speak')} placement="right" arrow>
								<IconButton
									sx={{ p: '2px' }}
									aria-label="directions"
									onClick={handleOnClickVolumeIcon}
								>
									<VolumeUpOutlinedIcon />
								</IconButton>
							</Tooltip>
						</Typography>
					</Stack>
					<Tooltip title={t('save')} placement="right" arrow>
						<IconButton onClick={onVote}>
							{highlight ? <BookmarkIcon /> : <BookmarkBorderIcon />}
						</IconButton>
					</Tooltip>
				</Stack>

				<Box
					component="fieldset"
					sx={{ borderRadius: 1, borderColor: '#C4C4C4', borderWidth: '1px' }}
				>
					<Typography component="legend" variant="caption">
						{t('answer')}
					</Typography>
					<Typography sx={{ my: 1, color: 'black', fontWeight: '500' }}>
						{readMore ? answer : answerOnReadLess}
						{showReadMoreButton && (
							<Button
								style={{ color: '#212121', fontWeight: 'bold' }}
								onClick={() => setReadMore(!readMore)}
							>
								{!readMore ? t('read_more') : t('read_less')}
							</Button>
						)}
					</Typography>

					<Button variant="text" onClick={handleShowRecommendedText}>
						{t('why_this')}
					</Button>
					{isRecommendedOpen && (
						<Typography sx={{ fontStyle: 'italic' }}>
							{`${t('recommended_because')} ${reasonForRecommendation}`}{' '}
						</Typography>
					)}

					<Divider sx={{ mt: 3 }} />

					<Stack direction="row" placement="end">
						<Tooltip title={t('copy_text')} placement="bottom" arrow>
							<IconButton>
								<ContentCopyIcon onClick={onCopy} />
							</IconButton>
						</Tooltip>
					</Stack>
				</Box>
			</Paper>
		</>
	);
}

Answer.propTypes = {
	questionData: PropTypes.object,
	questionNumber: PropTypes.number,
	lang: PropTypes.any
};
export default Answer;
