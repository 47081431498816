import React from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';

function InfoAlert() {
	const { t } = useTranslation(['icims-copilot']);
	return (
		<Stack sx={{ mt: 1.5, mb: 1.5 }}>
			<Alert severity="info">{t('multiple_answers_possible_alert')}</Alert>
		</Stack>
	);
}

export default InfoAlert;
