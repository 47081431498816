import React, { useState } from 'react';
import { Logo } from '@icims-design-system/mui-core';
import {
	Toolbar,
	IconButton,
	AppBar,
	Button,
	Typography,
	Dialog,
	DialogContent
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { useGetLibrarySizeQuery } from 'redux/slices/interviewAssistantSlice';
import Badge from '@mui/material/Badge';
import Library from 'components/InterviewAssistant/Library/Library';

const drawerWidth = '250px !important';
const mobileDrawerWidth = '70vw !important';

const useStyles = makeStyles()((theme) => {
	const color = theme.palette.common.white;
	return {
		IconButton: {
			mr: 2,
			display: 'initial',
			[theme.breakpoints.up('sm')]: {
				display: 'none !important'
			}
		},
		AppBar: {
			[theme.breakpoints.up('sm')]: {
				zIndex: '2000 !important'
			}
		},
		drawer: {
			width: mobileDrawerWidth,
			[theme.breakpoints.up('sm')]: {
				width: drawerWidth
			},
			flexShrink: 0
		},
		drawerPaper: {
			width: mobileDrawerWidth,
			[theme.breakpoints.up('sm')]: {
				width: drawerWidth
			}
		},
		select: {
			'&:after': {
				borderBottomColor: 'white'
			},
			'& .MuiSvgIcon-root': {
				color: 'white'
			}
		},
		svg: {
			width: 60,
			height: 20,
			color
		},
		accent: {
			color
		}
	};
});

const Sidebar = () => {
	const { data: totalLibrarySize } = useGetLibrarySizeQuery();
	const [open, setOpen] = useState(false);

	const { classes } = useStyles();

	const handleLibrary = () => {
		setOpen(true);
	};

	const handleHomePage = () => {
		setOpen(false);
	};

	return (
		<nav>
			<>
				<AppBar size="web" color="primary" position="fixed" className={classes.AppBar}>
					<Toolbar>
						<Button aria-label="iCIMS" onClick={handleHomePage}>
							<Logo classes={{ svg: classes.svg, accent: classes.accent }} />
						</Button>
						<Typography sx={{ flexGrow: 1 }} />
						<Typography sx={{ flexGrow: 1 }} />

						<IconButton>
							<Badge badgeContent={totalLibrarySize} color="primary">
								<Button
									style={{ color: 'white', fontSize: '17px', minWidth: 0, padding: 0 }}
									onClick={handleLibrary}
								>
									<BookmarkIcon />
								</Button>
							</Badge>
						</IconButton>
					</Toolbar>
				</AppBar>
				<Toolbar />
				<Dialog open={open} fullScreen>
					<DialogContent>
						<Library />
					</DialogContent>
				</Dialog>
			</>
		</nav>
	);
};

export default Sidebar;
