import React from 'react';
import { InputLabel, Select, MenuItem } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { useTranslation } from 'react-i18next';

export const DropDownInput = (props) => {
	const { t } = useTranslation(['icims-copilot']);
	const { title, value, options, onChange, defaultValue } = { ...props };

	return (
		<FormControl aria-disabled sx={{ marginBottom: 0, minWidth: '94%' }}>
			<InputLabel id="select-disabled-input-label">{t(title)}</InputLabel>
			<Select
				label={t(title)}
				labelId="select-disabled-input-label"
				id="select-disabled"
				value={value === '' ? defaultValue : value}
				onChange={(event) => onChange(event)}
			>
				<MenuItem value={defaultValue}>{defaultValue}</MenuItem>
				{options.map((option, index) => (
					<MenuItem key={index} value={option}>
						{option}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};
