import { apiSlice } from './apiSlice';

export const interviewAssistantSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getIndustry: builder.query({
			query(lang) {
				return {
					url: `/industry?lang=${lang}`,
					method: 'GET'
				};
			},
			transformResponse: (response) => response.values
		}),
		generateQuestions: builder.mutation({
			query: ({ noOfQuestions, industry, keyword, difficulty, experience,language }) => ({
				url: `generate`,
				method: 'GET',
				params: {
					widgetId: '64e45160bd7eb77398eb18c0',
					customerId: 123,
					userId: 123,
					lang: language,
					1: noOfQuestions,
					2: industry,
					3: keyword,
					4: experience,
					5: difficulty
				}
			}),
			transformResponse: (response) => response.questionList,
			transformErrorResponse: (response) => response.status
		}),
		getSkills: builder.query({
			query(lang) {
				return {
					url: `/skill?lang=${lang}`,
					method: 'GET'
				};
			},
			transformResponse: (response) => response.values
		}),
		uploadResume: builder.mutation({
			query(body) {
				return {
					url: `/widget/parse?widgetId=64ef32febbcfc57b6078d69f`,
					method: 'POST',
					body
				};
			},
			transformResponse: (responseData) => responseData.keywords
		}),
		getSavedQuestion: builder.query({
			query: () => ({
				url: `history`,
				method: 'GET'
			}),
			transformResponse: (response) => response.history
		}),
		save: builder.mutation({
			query(payload) {
				return {
					url: `/save`,
					method: 'POST',
					body: payload
				};
			},
			transformResponse: (response) => response,
			invalidatesTags: ['library']
		}),
		getLibrarySize: builder.query({
			query: () => ({
				url: `library/size`,
				method: 'GET'
			}),
			transformResponse: (response) => response.totalLibrarySize,
			providesTags: ['library']
		})
	})
});

export const {
	useGetIndustryQuery,
	useGetSkillsQuery,
	useGenerateQuestionsMutation,
	useUploadResumeMutation,
	useGetSavedQuestionQuery,
	useSaveMutation,
	useGetLibrarySizeQuery
} = interviewAssistantSlice;
