import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';

const baseUrl = 'https://copilot-develop.sandbox.icimsmco.net/api/v1/';

export const validateMagicLink = createAsyncThunk('validate/magicLink', async ({payload,lang}) => {
	const response = await fetch(`${baseUrl}magiclink/validate?lang=${lang}`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(payload)
	});
	const data = await response.json();
	return data;
});

export const interviewAssitantBasicSlice = createSlice({
	name: 'iaBasicSlice',
	initialState: {
		details: {},
		interviewPortalDetails: {
			url: '',
			jobId: ''
		}
	},
	reducers: {
		setInterviewPortalDetails: (state, action) => {
			state.interviewPortalDetails.url = action.payload.url;
			state.interviewPortalDetails.jobId = action.payload.jobId;
		}
	},
	extraReducers: (builder) => {
		builder.addCase(validateMagicLink.fulfilled, (state, action) => {
			state.details = action.payload;
		});
	}
});
export const { setInterviewPortalDetails } = interviewAssitantBasicSlice.actions;

export const getMagicLinkDetails = createSelector(
	(state) => state.interviewAssistant,
	(interviewAssistant) => interviewAssistant.details
);

export const getInterviewPortalDetails = createSelector(
	(state) => state.interviewAssistant,
	(interviewAssistant) => interviewAssistant.interviewPortalDetails
);

export default interviewAssitantBasicSlice.reducer;
