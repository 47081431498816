import { configureStore } from '@reduxjs/toolkit';
import { apiSlice } from './slices/apiSlice';
import { interviewAssitantBasicSlice } from './slices/interviewAssistantBasicSlice';
export default configureStore({
	reducer: {
		[apiSlice.reducerPath]: apiSlice.reducer,
		interviewAssistant: interviewAssitantBasicSlice.reducer
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware()
			.concat(apiSlice.middleware)
});
