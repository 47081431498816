/* eslint-disable no-magic-numbers */
import React from 'react';

import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Card,
	Stack,
	CircularProgress,
	Typography,
	TablePagination
} from '@mui/material';

import { useGetSavedQuestionQuery } from 'redux/slices/interviewAssistantSlice';
import { useTranslation } from 'react-i18next';

function Library() {
	const { data: history = [], isLoading } = useGetSavedQuestionQuery();
	const { t } = useTranslation(['icims-copilot']);

	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(5);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(event.target.value);
		setPage(0);
	};

	return (
		<Paper elevation={0} style={{ marginTop: '50px' }}>
			<Typography sx={{ p: 3, pb: 2 }} component={Paper} variant="h2">
				{t('saved_questions')}
			</Typography>
			<Card sx={{ px: 2, m: 0, boxShadow: 'none', mt: 1 }}>
				{isLoading ? (
					<Stack justifyContent="center" alignItems="center" width="100%" height="100%">
						<CircularProgress />
					</Stack>
				) : (
					<>
						<TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
							<Table
								sx={{ minWidth: 650, borderBottom: 1, borderColor: 'rgba(224, 224, 224, 1)' }}
								aria-label="simple table"
							>
								<TableHead>
									<TableRow>
										<TableCell>{t('question')}</TableCell>
										<TableCell>{t('answer')}</TableCell>
										<TableCell>{t('skills')}</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{history
										.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
										.map((row) => (
											<TableRow
												key={row.name}
												sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
											>
												<TableCell component="th" scope="row">
													{row.question}
												</TableCell>
												<TableCell>{row.answer}</TableCell>
												<TableCell>{row.skills}</TableCell>
											</TableRow>
										))}
								</TableBody>
							</Table>
						</TableContainer>
						<TablePagination
							rowsPerPageOptions={[5, 10, 50, 100]}
							component="div"
							count={history.length}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
							labelRowsPerPage={t('rows_per_page')}
							SelectProps={{
								inputProps: { 'aria-label': t('rows_per_page') },
								native: true
							}}
						/>
					</>
				)}
			</Card>
		</Paper>
	);
}
export default Library;
