import React from 'react';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import { SideBar } from './components';
import { connect } from 'react-redux';
import InterviewAssistant from 'components/InterviewAssistant';
import Library from 'components/InterviewAssistant/Library/Library';

export const Root = () => (
	<Router>
		<SideBar />
		<main style={{ height: '92vh' }}>
			<Route exact path="/" component={InterviewAssistant} />
			<Route exact path="/library" component={Library} />
		</main>
	</Router>
);

export default connect((state) => state)(Root);
