import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import AnswerContainer from './AnswerContainer';
import {
	Button,
	Typography,
	Divider,
	InputLabel,
	Select,
	MenuItem,
	Grid,
	ListItemText,
	Chip,
	Checkbox,
	FormControl,
	Paper,
	Stack,
	IconButton,
	Avatar
} from '@mui/material';
import AutoAwesome from './autoAwesome.svg';
import { DropDownInput } from './DropDownInput';
import ArrowBackIcon from '@icims-design-system/icons/build/ArrowBack';
import {
	useGetIndustryQuery,
	useGetSkillsQuery,
	useGenerateQuestionsMutation
} from 'redux/slices/interviewAssistantSlice';
import { clone, flatten, uniq, times, get } from 'lodash';
import MySvgImage from './copilot.svg';
import LoadingGif from './loadingstatesparkleike.gif';
import { useDispatch, useSelector } from 'react-redux';
import {
	getMagicLinkDetails,
	validateMagicLink,
	getInterviewPortalDetails,
	setInterviewPortalDetails
} from 'redux/slices/interviewAssistantBasicSlice';
import { useTranslation } from 'react-i18next';

function InterviewAssistant() {
	const { t , i18n} = useTranslation(['icims-copilot']);
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const [initialLoad, setInitialLoad] = useState(false);
	const text = useMemo(
		() => ['got_it', 'considering_your_requirements', 'working_on_recommendations'],
		[]
	);
	const [msg, setMsg] = useState('');
	const [currentIndex, setCurrentIndex] = useState(0);

	const returnUrl = queryParams.get('returnUrl');
	const jobId = queryParams.get('job');

	const lang = i18n.language;

	const { data: industryOptions = [] } = useGetIndustryQuery(lang);
	const { data: skillOptions = [] } = useGetSkillsQuery(lang);
	const history = useHistory();
	const dispatch = useDispatch();
	const magicLink = useSelector(getMagicLinkDetails);
	const [
		generateQuestions,
		{ data: questionList = [], isLoading, isSuccess }
	] = useGenerateQuestionsMutation();
	const [selection, setSelection] = React.useState([]);
	const [filteredSkills, setFilteredSkills] = React.useState([]);

	const interViewPortalUrl = useSelector(getInterviewPortalDetails);

	const [configData, setConfigData] = React.useState({
		noOfQuestions: 5,
		industry: '',
		difficulty: '',
		experience: ''
	});

	const skillSet = useMemo(() => {
		const skills = questionList.map((question) => question.skills.split(/\s*,\s*/));

		return uniq(flatten(skills));
	}, [questionList]);

	const formattedQuestions = useMemo(() => {
		if (!filteredSkills.length) {
			return questionList;
		}

		return questionList.filter((question) => {
			const { skills } = question;

			return filteredSkills.some((filterSkills) => skills.includes(filterSkills));
		});
	}, [questionList, filteredSkills]);

	const handleDropDownChange = (event, property) => {
		const targetValue = event.target.value;
		const newConfigData = clone(configData);

		newConfigData[property] = targetValue;
		setConfigData(newConfigData);
	};

	const handleChangeKeywords = (event) => {
		setSelection(event.target.value);
	};

	useEffect(() => {
		const payload = {
			jobId: interViewPortalUrl.jobId
		}
		dispatch(validateMagicLink({ payload ,lang}));
	}, [dispatch, interViewPortalUrl.jobId, lang]);

	useEffect(() => {
		dispatch(setInterviewPortalDetails({ url: returnUrl, jobId }));
	});

	const shuffle = useCallback(() => {
		if (currentIndex < text.length) {
			setMsg(text[currentIndex]);
			setCurrentIndex(currentIndex + 1);
		}
	}, [currentIndex, text]);

	useEffect(() => {
		let intervalId = null;
		if (msg !== text[2]) {
			// eslint-disable-next-line no-magic-numbers
			intervalId = setInterval(shuffle, 2000);
		}
		return () => clearInterval(intervalId);
	}, [shuffle, msg, text]);

	useEffect(() => {
		setConfigData({
			noOfQuestions: configData.noOfQuestions,
			industry: get(magicLink, 'industry') ? magicLink.industry : '',
			difficulty: get(magicLink, 'difficulty') ? magicLink.difficulty : '',
			experience: get(magicLink, 'experience') ? magicLink.experience : ''
		});
		setSelection(get(magicLink, 'skills') ? magicLink.skills : []);
	}, [configData.noOfQuestions, magicLink]);

	useEffect(() => {
		if (initialLoad === false && selection.toString().length > 0) {
			generateQuestions({
				noOfQuestions: configData.noOfQuestions,
				industry: configData.industry,
				keyword: selection.toString(),
				experience: configData.experience,
				difficulty: configData.difficulty,
				language: lang
			});
			setInitialLoad(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selection, initialLoad]);

	const handleDelete = (value) => {
		setSelection(selection.filter((color) => color !== value));
	};

	const handleClear = () => {
		setSelection([]);
		setConfigData({
			noOfQuestions: '',
			industry: '',
			difficulty: '',
			experience: ''
		});
	};

	const handleBack = () => {
		if (interViewPortalUrl.url) window.open(`${interViewPortalUrl.url}`, '_self');
		else history.goBack();
	};

	const onGenerateClick = () => {
		setMsg(text[0]);
		setCurrentIndex(0);
		generateQuestions({
			noOfQuestions: configData.noOfQuestions,
			industry: configData.industry,
			keyword: selection.toString(),
			experience: configData.experience,
			difficulty: configData.difficulty,
			language: lang
		});
	};

	const onSkillClick = (skill) => {
		const selectedSkills = [...filteredSkills];
		const index = filteredSkills.indexOf(skill);

		if (index > -1) {
			selectedSkills.splice(index, 1);
		} else {
			selectedSkills.push(skill);
		}

		setFilteredSkills(selectedSkills);
	};

	return (
		<>
			<Grid container sx={{ mt: -1, height: 'inherit' }} justifyContent="space-evenly">
				<Grid item xs={3} component={Paper} sx={{ pl: 1, pt: 2 }}>
					<Grid container sx={{ alignItems: 'center' }}>
						<Grid item xs={10}>
							<Typography style={{ paddingRight: '1px' }} variant="h2">
								<IconButton>
									<ArrowBackIcon onClick={handleBack} />
								</IconButton>
								<img
									src={MySvgImage}
									width="40.000000pt"
									height="40.000000pt"
									alt="iCIMS Copilot"
									style={{
										verticalAlign: 'middle',
										paddingRight: '2px',
										position: 'relative',
										top: '-3px'
									}}
								/>
								{t('icims_copilot')}
							</Typography>
						</Grid>
						<Grid item xs={2}>
							<Button onClick={handleClear}>{t('clear')}</Button>
						</Grid>
					</Grid>
					<Divider sx={{ my: 1 }} />
					<DropDownInput
						title="no_of_questions"
						id="select-disabled-input-label"
						// eslint-disable-next-line no-magic-numbers
						options={times(10, (i) => i + 1)}
						value={configData.noOfQuestions}
						onChange={(evt) => handleDropDownChange(evt, 'noOfQuestions')}
					/>
					<DropDownInput
						title="industry"
						id="select-disabled-input-label"
						options={industryOptions}
						defaultValue={get(magicLink, 'industry') ? magicLink.industry : ''}
						value={configData.industry}
						onChange={(evt) => handleDropDownChange(evt, 'industry')}
					/>
					<DropDownInput
						title="difficulty"
						id="select-disabled-input-label"
						options={[t('easy'), t('medium'), t('advanced')]}
						defaultValue={get(magicLink, 'difficulty') ? magicLink.difficulty : ''}
						value={configData.difficulty}
						onChange={(evt) => handleDropDownChange(evt, 'difficulty')}
					/>
					<DropDownInput
						title="experience"
						id="select-disabled-input-label"
						options={[t('experience_years',{experienceRange:'0-3'}), t('experience_years',{experienceRange:'3-5'}), t('experience_years',{experienceRange:'5-7'}), t('experience_years',{experienceRange:'9-12'}), t('experience_years',{experienceRange:'12+'})]}
						defaultValue={get(magicLink, 'experience') ? magicLink.experience : ''}
						value={configData.experience}
						onChange={(evt) => handleDropDownChange(evt, 'experience')}
					/>
					<Divider sx={{ mt: '5%', mb: '5%' }} />
					<Stack direction="row" spacing={1} justifyContent={'space-between'} mr={1}>
						<Typography variant="h3">{t('skills')}</Typography>
					</Stack>
					<FormControl
						sx={{
							minWidth: '94%',
							maxWidth: '94%',
							marginBottom: '5%'
						}}
						size="medium"
					>
						<InputLabel required id="multiple-select-with-chips-medium">
							{t('skills')}
						</InputLabel>
						<Select
							label="Multi Select (medium)"
							labelId="multiple-select-with-chips-medium"
							id="multi-select-with-chips-demo-medium"
							multiple
							value={selection}
							onChange={handleChangeKeywords}
							renderValue={(selected) => (
								<div style={{ display: 'flex', flexWrap: 'wrap' }}>
									{selected.map((value) => (
										<Chip
											key={value}
											label={value}
											sx={{ mb: 0.5, mr: 0.5 }}
											size="medium"
											onDelete={() => handleDelete(value)}
											onMouseDown={(event) => {
												event.stopPropagation();
											}}
										/>
									))}
								</div>
							)}
						>
							{skillOptions.map((option, index) => (
								<MenuItem key={index} value={option}>
									<Checkbox checked={selection.indexOf(option) > -1} />
									<ListItemText primary={option} />
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<Divider sx={{ mt: '5%', mb: '5%' }} />
					<Button
						variant="contained"
						style={{ float: 'right', marginRight: '3%' }}
						onClick={onGenerateClick}
						disabled={isLoading || !selection.length}
						startIcon={
							<Avatar
								alt="copilot"
								src={AutoAwesome}
								variant="circular"
								style={{ height: '20px', width: '20px' }}
							/>
						}
					>
						{t('generate_questions')}
					</Button>
				</Grid>
				<Grid style={{ height: 'inherit' }} item xs={9}>
					<Grid
						style={{
							height: 'inherit',
							overflowY: 'auto',
							paddingTop: '16px',
							paddingLeft: '16px'
						}}
					>
						{isLoading && (
							<Stack justifyContent="center" alignItems="center" width="100%" height="100%">
								<img src={LoadingGif} alt="My GIF" />
								<Typography variant="h3">{t(msg)}</Typography>
							</Stack>
						)}
						{isSuccess && (
							<AnswerContainer
								questionList={formattedQuestions}
								skillSet={skillSet}
								filteredSkills={filteredSkills}
								onSkillClick={onSkillClick}
								t={t}
								lang={lang}
							/>
						)}
					</Grid>
				</Grid>
			</Grid>
		</>
	);
}

export default InterviewAssistant;
