import React, { Suspense } from 'react';
import { I18nextProvider } from 'react-i18next';
import { CircularProgress } from '@mui/material';
import { ThemeProvider } from '@icims-design-system/mui-core';
import localeConfig from './config/localeConfig';
import Root from 'Root';
import { Provider as ReduxProvider } from 'react-redux';
import store from './redux/store';
import i18nextConfig from '@icims-design-system/i18next';

const App = () => (
	<I18nextProvider i18n={i18nextConfig({ ...localeConfig })}>
		<ThemeProvider>
			<Suspense fallback={<CircularProgress />}>
				<ReduxProvider store={store}>
					<Root />
				</ReduxProvider>
			</Suspense>
		</ThemeProvider>
	</I18nextProvider>
);

export default App;
